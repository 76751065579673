import React, { useState, useEffect } from "react";
import { builder, BuilderComponent } from "@builder.io/react";
import { graphql } from "gatsby";
import { Provider } from "../context/Context";
import getOrderData from "../functions/orderData";
import { getSalesTaxes } from "../functions/salesTaxes";

import "../builder-settings";

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const Editing = ({ data }) => {
  const userToken = "token";
  const content = data?.allBuilderModels.oneBanners.content;

  const { countdownDuration = 900 } = content?.data;

  const selectorData = data?.upsellCheckoutData;

  const endTime = new Date();
  endTime.setSeconds(endTime.getSeconds() + countdownDuration);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [upsellCart, setUpsellCart] = useState([]);
  const [preFetch, setPreFetch] = useState(false);
  const [salesTaxes, setSalesTaxes] = useState({
    label: "Sales tax",
    tax: []
  });
  const [orderData, setOrderData] = useState({});

  const context = {
    selectorData: selectorData,
    currentIndex: currentIndex,
    setCurrentIndex: setCurrentIndex,
    buttonClicked: buttonClicked,
    setButtonClicked: setButtonClicked,
    userToken: userToken,
    upsellCart: upsellCart,
    setUpsellCart: setUpsellCart,
    salesTaxes: salesTaxes,
    orderData: orderData,
    countDownEnd: endTime
  };

  useEffect(() => {
    if (!preFetch) {
      // // get order data
      getOrderData(userToken).then(data => {
        setOrderData(data);
      });

      // // get sales taxes
      getSalesTaxes(selectorData?.store, selectorData?.offers, userToken).then(
        data => {
          setSalesTaxes(data);
        }
      );

      setPreFetch(true);
    }
  }, [
    currentIndex,
    selectorData,
    preFetch,
    setPreFetch,
    userToken,
    setSalesTaxes,
    setOrderData,
    setCurrentIndex,
    upsellCart
  ]);

  return (
    <Provider value={context}>
      <BuilderComponent content={content} model="banners" />
    </Provider>
  );
};

export const query = graphql`
  query BannersEditingPageQuery {
    allBuilderModels {
      oneBanners {
        content
      }
    }

    upsellCheckoutData {
      checkoutUrl
      currency {
        code
        symbol
      }
      offers {
        checkoutData {
          country
          discounted_price
          image
          price
          product_id
          quantity
          splitFlag
          split_price
          title
          title_index
          variant_id
        }
        discountCode
        discountRate
        displayTitle
        nextIndexNo
        nextIndexYes
        offerId
        productName
        variantId
        tag
      }
      store
      checkout
    }
  }
`;

export default Editing;
