import _axios from "axios";
import checkoutApiUrl from "../utils/checkoutApiUrl";

export const getSalesTaxes = async (selectorData, token) => {
  const { store, offers } = selectorData;
  if (!token) {
    return;
  }

  try {
    const prices = offers.map((offer, index) => {
      let price = {};
      price[`offer${index}`] = offer?.checkoutData?.discounted_price;
      return price;
    });

    let res = await _axios.post(
      `${checkoutApiUrl()}/checkout/${token}/taxcalculate`,
      {
        data: {
          store: store,
          prices: prices
        }
      }
    );

    return res?.data;
  } catch (e) {
    console.log("error fetching sales tax");
    console.log(e);
  }
};

export const getSalesTaxesV2 = async (amount = 0, address) => {
  try {
    const res = await _axios.post(
      `${process.env.GATSBY_CHECKOUT_API_URL_V2}/checkout/tax`,
      {
        amount,
        address
      }
    );
    return res?.data?.data;
  } catch (e) {
    console.log("error fetching sales tax");
    console.log(e);
  }
};
