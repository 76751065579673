import _axios from "axios";
import checkoutApiUrl from "../utils/checkoutApiUrl";

const getOrderData = async (token, apiVersion2 = false) => {
  if (!token) {
    return;
  }

  try {
    let res = await _axios.get(
      `${checkoutApiUrl(apiVersion2)}/checkout/${token}`
    );
    return apiVersion2 ? res?.data.data : res?.data;
  } catch (e) {
    console.log("error fetching order data");
    console.log(e);
  }
};

export default getOrderData;
